<template>
    <div @click="showDropDown" class="my-dropdown">
      <div :style="buttonContainerStyles" class="dropdown-button-container">
        <slot name="button"></slot>
      </div>
        <transition name="fast-fade">
            <div :style="dropdownStyles" class="dropdown-container" v-show="show_dropdown">
              <slot v-show="show_dropdown" name="dropdown"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "dropdown",
    props: {
      buttonContainerStyles: {
        type: String,
        default: ''
      },
      dropdownStyles: {
        type: String,
        default: ''
      }
    },
    data: function () {
        return {
            show_dropdown: false,
        }
    },
    methods: {
        showDropDown() {
            if (this.show_dropdown) {
                this.show_dropdown = false;
                window.removeEventListener("click", this.outsideClicked);
                return;
            }
            this.show_dropdown = true
            setTimeout(() => {
              window.addEventListener("click", this.outsideClicked);
            })
        },
        outsideClicked(e) {
            // if (!this.hasClass(e, 'my-dropdown')) {
            //     this.show_dropdown = false;
            //     window.removeEventListener("click", this.outsideClicked);
            // }
            this.show_dropdown = false;
            window.removeEventListener("click", this.outsideClicked);
        },
        hasClass(element, className) {
          let regex = new RegExp('\\b' + className + '\\b');
          do {
            if (regex.exec(element.className)) {
              return true;
            }
            element = element.parentNode;
          } while (element);
          return false;
        }
    }
}
</script>


<style>
.list-enter-active, .list-leave-active {
    transition: all 350ms;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}
.fast-fade-enter-active, .fast-fade-leave-active {
  transition: all 250ms;
}
.fast-fade-enter, .fast-fade-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(5px);
}
.dropdown-button-container {
  height: 100%;
}
.dropdown-container {
  position: absolute;
  left: 50%;
  transform: translateX(-9%);
  z-index: 2;
}

@media screen and (max-width: 768px){
  .dropdown-container {
    left: 208%;
  }
}

.my-dropdown {
  position: relative;
}
</style>
