<template>
  <b-modal v-if="this.$store.state.header.user" id="logout-modal" title="آیا می‌خواهید خارج شوید؟">
    <template #modal-footer="{ ok, cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div class="w-100 d-flex justify-content-center" style="gap: 16px">
        <b-button :disabled="sendingRequest" class="px-4" size="sm" variant="primary" @click="() => {logout(ok)}">
          بله
        </b-button>
        <b-button :disabled="sendingRequest" class="px-4" size="sm" variant="secondary" @click="cancel()">
          خیر
        </b-button>
      </div>
    </template>
  </b-modal>

</template>

<script>
export default {
  name: "LogoutModal",
  data() {
    return {
      sendingRequest: false
    }
  },
  methods:{
    logout(callback) {
      this.sendingRequest = true;
      this.$axios.post('/api/logout')
          .then((response) => {
            this.$store.commit('header/setUser' , false )
            this.$store.commit('header/setCart' , [])
            this.$root.success_notification(response.data.message)
            callback()
            this.$router.push('/')
          })
          .catch(function (error) {
            this.$root.error_notification(error)
          }).finally(() => {
        this.sendingRequest = false;
      });

      return this.$store.state.header.user;
    }
  }
}
</script>

<style scoped>

</style>