<template>
  <section class="side-menu all-categories-side bg-white text-right">
    <button
      type="button"
      class="side-menu-btn side-menu-btn1 weight-bold mr-4 mt-2 px-2 bg-color-ea text-white rounded"
      @click="allCategoriesClose()"
    >
      X
    </button>
    <div class="accordion  menu-cat-mobile mt-3" id="accordionExample">
      <div class="card">
        <div
          class="card-header my-1 pr-3 d-flex justify-content-between "
          id="headingFour"
        >
          <span
            class="fontsize12 weight-bold text-color-themeBlue my-auto cursor-pointer"
            data-toggle="collapse"
            data-target="#collapseFour"
            >همه دسته بندی ها</span
          >
          <button
            class="all-categories-side-btn collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            <svg
              class="svg1"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                stroke="#fff"
                stroke-width="3"
                d="M16.2559 4.24279C16.5805 4.56431 16.5827 5.08555 16.2537 5.40707L11.3882 10.1717L11.3023 10.2451C10.9734 10.4897 10.5033 10.4642 10.2048 10.1684C10.043 10.0082 9.96049 9.79753 9.96049 9.58794C9.96049 9.37725 10.043 9.16546 10.207 9.00525L15.0724 4.2395L15.1584 4.16609C15.4873 3.92149 15.9573 3.94706 16.2559 4.24279ZM16.3291 18.6783C16.5802 19.001 16.5535 19.4636 16.256 19.7583C15.9303 20.0798 15.4005 20.0809 15.0725 19.7594L7.74651 12.5828L7.67131 12.4987C7.55732 12.3523 7.5 12.1766 7.5 12.0001C7.5 11.7905 7.58254 11.5798 7.74428 11.4196C8.06998 11.097 8.5998 11.0959 8.92773 11.4174L16.2538 18.594L16.3291 18.6783Z"
              />
            </svg>
            <svg
              class="svg2 d-none"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 8.5L12 15.5L5 8.5"
                stroke="#fff"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div
          id="collapseFour"
          class="sub-all-categories-side-btn text-right collapse"
          aria-labelledby="headingFour"
          data-parent="#accordionExample"
        >
          <div class="card-body py-0">
            <ul class="all-categorie-sub-menu pr-3 mb-0">
              <li
                v-for="category in $store.state.header.categories"
                :key="category.id"
                @click="openModalCategories(category.name)"
              >
                <router-link
                  :to="
                    '/products/category/' + category.id + '/' + category.slug
                  "
                  >{{ category.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <router-link
        v-on:click.native="allCategoriesClose"
        to="/"
        class="d-block text-color-themeBlue fontsize12 weight-bold mr-3 my-1 mt-3 py-1"
        >صفحه اصلی</router-link
      >
      <template v-if="$store.state.header.menu && $store.state.header.menu[0]">
        <div
          v-for="(menu, index) in $store.state.header.menu[0].items"
          :key="index"
          class="card mobile-menu"
        >
          <div
            class="card-header my-1 pr-3 d-flex justify-content-between"
            id="headingOne"
          >
            <a
              @click="allCategoriesClose()"
              v-if="menu && !menu.route && menu.link"
              :href="menu.link ? menu.link : false"
              class="d-block my-auto text-color-444 fontsize12 font-weight-bold py-1"
            >
              {{ menu.title }}
            </a>
            <router-link
              v-else
              v-on:click.native="allCategoriesClose"
              :to="menu.route ? menu.route : ''"
              class="d-block my-auto text-color-444 fontsize12 py-1 font-weight-bold"
              >{{ menu.title }}</router-link
            >

            <button
              v-if="menu && menu.children && menu.children.length"
              class="all-categories-side-btn collapsed py-0"
              type="button"
              data-toggle="collapse"
              :data-target="'#collapse' + index"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <svg
                class="svg1"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  stroke="#333"
                  stroke-width="3"
                  d="M16.2559 4.24279C16.5805 4.56431 16.5827 5.08555 16.2537 5.40707L11.3882 10.1717L11.3023 10.2451C10.9734 10.4897 10.5033 10.4642 10.2048 10.1684C10.043 10.0082 9.96049 9.79753 9.96049 9.58794C9.96049 9.37725 10.043 9.16546 10.207 9.00525L15.0724 4.2395L15.1584 4.16609C15.4873 3.92149 15.9573 3.94706 16.2559 4.24279ZM16.3291 18.6783C16.5802 19.001 16.5535 19.4636 16.256 19.7583C15.9303 20.0798 15.4005 20.0809 15.0725 19.7594L7.74651 12.5828L7.67131 12.4987C7.55732 12.3523 7.5 12.1766 7.5 12.0001C7.5 11.7905 7.58254 11.5798 7.74428 11.4196C8.06998 11.097 8.5998 11.0959 8.92773 11.4174L16.2538 18.594L16.3291 18.6783Z"
                />
              </svg>
              <svg
                class="svg2 d-none"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 8.5L12 15.5L5 8.5"
                  stroke="#333"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>

          <div
            v-if="menu && menu.children && menu.children.length"
            :id="'collapse' + index"
            class="sub-all-categories-side-btn text-right collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body py-0">
              <ul class="all-categorie-sub-menu pr-3">
                <li
                  v-for="(childMenu, childIndex) in menu.children"
                  :id="'submenu-' + childIndex"
                  :key="childIndex"
                  class="position-relative"
                >
                  <!-- <a href="">{{ childMenu.title }}</a> -->
                  <div
                    class="card-header my-1 pr-3 d-flex justify-content-between"
                    id="headingMenu"
                  >
                    <router-link
                      v-if="childMenu.route"
                      :to="childMenu.route ? childMenu.route : ''"
                      :target="childMenu.target"
                      class="d-inline-block my-auto text-color-444 fontsize12 font-weight-bold"
                    >
                      {{ childMenu.title }}
                    </router-link>
                    <a
                      v-else
                      @click="allCategoriesClose()"
                      :href="childMenu.link ? childMenu.link : ''"
                      :target="childMenu.target"
                      class="d-block my-auto text-color-444 fontsize12 font-weight-bold"
                      >{{ childMenu.title }}</a
                    >

                    <button
                      v-if="childMenu.children != ''"
                      class="all-categories-side-btn collapsed py-0 position-absolute"
                      style="left:0"
                      type="button"
                      data-toggle="collapse"
                      :data-target="'#collapse-menu' + childIndex"
                      aria-expanded="true"
                      aria-controls="collapseMenu"
                    >
                      <svg
                        class="svg1"
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          stroke="#333"
                          stroke-width="3"
                          d="M16.2559 4.24279C16.5805 4.56431 16.5827 5.08555 16.2537 5.40707L11.3882 10.1717L11.3023 10.2451C10.9734 10.4897 10.5033 10.4642 10.2048 10.1684C10.043 10.0082 9.96049 9.79753 9.96049 9.58794C9.96049 9.37725 10.043 9.16546 10.207 9.00525L15.0724 4.2395L15.1584 4.16609C15.4873 3.92149 15.9573 3.94706 16.2559 4.24279ZM16.3291 18.6783C16.5802 19.001 16.5535 19.4636 16.256 19.7583C15.9303 20.0798 15.4005 20.0809 15.0725 19.7594L7.74651 12.5828L7.67131 12.4987C7.55732 12.3523 7.5 12.1766 7.5 12.0001C7.5 11.7905 7.58254 11.5798 7.74428 11.4196C8.06998 11.097 8.5998 11.0959 8.92773 11.4174L16.2538 18.594L16.3291 18.6783Z"
                        />
                      </svg>
                      <svg
                        class="svg2 d-none"
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 8.5L12 15.5L5 8.5"
                          stroke="#333"
                          stroke-width="4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  <div
                    v-if="
                      childMenu &&
                        childMenu.children &&
                        childMenu.children.length
                    "
                    :id="'collapse-menu' + childIndex"
                    class="sub-all-categories-side-btn text-right collapse"
                    aria-labelledby="headingMenu"
                    :data-parent="'#submenu-' + childIndex"
                  >
                    <div class="card-body py-0">
                      <ul class="all-categorie-sub-menu pr-3">
                        <li
                          @click="allCategoriesClose()"
                          v-for="(subMenu, index) in childMenu.children"
                          :key="index"
                        >
                          <!-- <a href="">{{ childMenu.title }}</a> -->
                          <router-link
                            v-if="subMenu.route"
                            :to="subMenu.route ? subMenu.route : ''"
                            :target="subMenu.target"
                          >
                            {{ subMenu.title }}
                          </router-link>
                          <a
                            v-else
                            :href="subMenu.link ? subMenu.link : ''"
                            :target="subMenu.target"
                            >{{ subMenu.title }}</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>

      <router-link
        @click.native="allCategoriesClose()"
        to="/about-us"
        class="d-block text-color-444 fontsize12 font-weight-bold mr-3 my-2 py-1"
        >درباره ما</router-link
      >
      <router-link
        @click.native="allCategoriesClose()"
        to="/contact"
        class="d-block text-color-444 fontsize12 font-weight-bold mr-3 my-2 py-1"
      >
        تماس با ما</router-link
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "MobileMenu",
  props: {
    allCategoriesClose: Function,
  },
  methods: {
    openModalCategories(name) {
      let hasClassClose = document
        .getElementById("all-categories")
        .classList.contains("all-categories-close");
      let catSide = document.getElementsByClassName("all-categories-side")[0];
      if (hasClassClose) {
        catSide.style.right = "-300px";
      }
      document
        .getElementById("all-categories")
        .classList.remove("all-categories-close");
      console.log("dd");
      this.$("#categoriesModal").modal("show");
      this.$store.commit("header/setCategoryTitle", name);
    },
  },
};
</script>

<style scoped></style>
